<template>
  <article v-if="global">
    <details v-for="i in iphones" :key="i.naam">
      <summary class="summ">
        {{ i.naam }}
      </summary>
      <div class="box content">
        <h4>
          SchermOrigineel: <span>{{ i.SchermOrigineel }}</span>
        </h4>
        <h4>
          Scherm AAA+: <span>{{ i.SchermAAA }}</span>
        </h4>
        <h4>
          Accu: <span>{{ i.Accu }}</span>
        </h4>
        <h4>
          Oplaad connector: <span>{{ i.Oplaadconnector }}</span>
        </h4>
        <h4>
          Camera: <span>{{ i.Camera }}</span>
        </h4>
        <h4>
          Camera Lens: <span>{{ i.CameraLens }}</span>
        </h4>
        <h4 v-if="i.HomeKnop">
          Home Knop: <span>{{ i.HomeKnop }}</span>
        </h4>
        <h4>
          Speaker: <span>{{ i.Speaker }}</span>
        </h4>
      </div>
    </details>
  </article>

  <article v-if="!global" class="my-2">
    <table>
      <tr>
        <th>Merk</th>
        <th>Scherm origineel</th>
        <th>Scherm AAA+</th>
        <th>Accu</th>
        <th>Oplaad Connector</th>
        <th>Camera</th>
        <th>Camera Lens</th>
        <th>Home Knop</th>
        <th>Speaker</th>
      </tr>
      <tr v-for="hu in iphones" :key="hu.naam">
        <td class="py-1" v-if="hu.naam">{{ hu.naam }}</td>
        <td v-if="!hu.naam">n.v.t</td>
        <td v-if="hu.SchermOrigineel">{{ hu.SchermOrigineel }}</td>
        <td v-if="!hu.SchermOrigineel">n.v.t</td>
        <td v-if="hu.SchermAAA">{{ hu.SchermAAA }}</td>
        <td v-if="!hu.SchermAAA">n.v.t</td>
        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>
        <td v-if="hu.Camera">{{ hu.Camera }}</td>
        <td v-if="!hu.Camera">n.v.t</td>
        <td v-if="hu.CameraLens">{{ hu.CameraLens }}</td>
        <td v-if="!hu.CameraLens">n.v.t</td>
        <td v-if="hu.HomeKnop">{{ hu.HomeKnop }}</td>
        <td v-if="!hu.HomeKnop">n.v.t</td>
        <td v-if="hu.Speaker">{{ hu.Speaker }}</td>
        <td v-if="!hu.Speaker">n.v.t</td>
      </tr>
    </table>
  </article>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
h4 {
  @include md {
    font-size: $s-font * 1.1;
  }
  @include lg {
    font-size: $m-font;
  }
}
</style>
