<template>
  <article class="home">
    <Jumbo />

    <p class="lead my-1">
      Wij doen allerlei soorten reparaties voor mobiel & tablet-apparaten
    </p>
    <Icons />
  </article>
</template>
<script>
// @ is an alias to /src
import Jumbo from '@/components/Jumbo.vue'

export default {
  name: 'HomeView',
  components: { Jumbo },
}
</script>

<style lang="scss" scoped>
.lead {
  background-image: url('@/assets/images/rep5.jpg');
  background-size: cover;
  background-position: center;
  padding: 2rem 0;
  padding-top: 7rem;
  color: #f7a400;
  text-shadow: 2px 2px 4px $dark;
  font-weight: bold;
  justify-content: start;
  align-items: flex-start;

  @include md {
    padding: 4rem 0;
    padding-top: 10rem;
    justify-items: bottom;
  }
  @include lg {
    padding: 6rem 0;
    padding-top: 20rem;
  }
}
</style>
