import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TarievenView from '@/views/TarievenView.vue'
import ContactView from '@/views/ContactView.vue'
import ServicesView from '@/components/Services.vue'
import AccesView from '@/views/AccesView.vue'
import Huwai from '@/components/Huwai.vue'
import Apple from '@/components/Apple.vue'
import Samsung from '@/components/Samsung.vue'
import LG from '@/components/LG.vue'
import HTC from '@/components/HTC.vue'
import Sony from '@/components/Sony.vue'
import Nokia from '@/components/Nokia.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesView,
    meta: {
      title: 'Services',
    },
  },
  {
    path: '/tarieven',
    name: 'Tarieven',
    component: TarievenView,
    meta: {
      title: 'Tarieven',
    },
  },
  {
    path: '/tarieven/huawei',
    name: 'Huawei',
    component: Huwai,
    meta: {
      title: 'Huawei',
    },
  },
  {
    path: '/tarieven/apple',
    name: 'Apple',
    component: Apple,
    meta: {
      title: 'Apple',
    },
  },
  {
    path: '/tarieven/samsung',
    name: 'Samsung',
    component: Samsung,
    meta: {
      title: 'Samsung',
    },
  },
  {
    path: '/tarieven/lg',
    name: 'LG',
    component: LG,
    meta: {
      title: 'LG',
    },
  },
  {
    path: '/tarieven/htc',
    name: 'HTC',
    component: HTC,
    meta: {
      title: 'HTC',
    },
  },
  {
    path: '/tarieven/nokia',
    name: 'Nokia',
    component: Nokia,
    meta: {
      title: 'Nokia',
    },
  },
  {
    path: '/tarieven/sony',
    name: 'Sony',
    component: Sony,
    meta: {
      title: 'Sony',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta: {
      title: 'Contact',
    },
  },

  {
    path: '/acces',
    name: 'Acces',
    component: AccesView,
    meta: {
      title: 'Accessoires',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  },
})

router.beforeEach((to, from, next) => {
  const docT = `${to.meta.title} | Q-Mobile  `
  document.title = docT
  next()
})

export default router
