<template>
  <article>
   
      <Gegevens/>

      <hr>
      <br>
      <Kaart />
  </article>
</template>

<script>
import Gegevens from '@/components/Gegevens.vue'
import Kaart from '@/components/Kaart.vue'
export default {
  name: 'ConTact',
  components: {
      Gegevens,
      Kaart
  }
}
</script>

