<template>
  <article>
    <!-- <img src="../assets/images/rep1.jpg" alt="icon" class="" /> -->
    <h1 class="h1">{{ welkom }}</h1>
    <br />

    <button class="btn btn-primary">
      <router-link to="/tarieven" class="text-light p-1">Tarieven</router-link>
    </button>
  </article>
</template>

<script>
export default {
  name: 'JumBo',
}
</script>

<style scoped lang="scss">
article {
  width: 100%;
  height: auto;
  min-height: 40vh;
  overflow: hidden;
  background-image: url('../assets/images/rep1.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  @include md {
    min-height: 85vh;
  }
  @include lg {
    min-height: 95vh;
  }

  .h1 {
    display: flex;
    color: $orange;
    text-shadow: 2px 1px 3px $dark;
    width: 100%;
    justify-content: center;
    margin-top: 5.5rem;
    // width: auto;
    // top: 11rem;
    // left: 7rem;
    // position: absolute;

    @include md {
      width: 100%;
      margin-top: 12rem;
      justify-content: center;
    }
    @include lg {
      font-size: xx-large;
      margin-top: 18rem;
      padding-left: 2rem;
    }
  }

  img {
    width: 100%;
    height: 40vh;
    position: absolute;
    top: 0;
    left: 0;

    @include md {
      height: 80vh;
    }
    @include lg {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
}
</style>
