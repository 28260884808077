<template>
  <div>
    <router-link to="/tarieven/apple" class='text-dark font-lg ml-1 mb-1'
      >Apple prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
    <router-link to="/tarieven/samsung" class='text-dark font-lg ml-1 mb-1'
      >Samsung prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
    <router-link to="/tarieven/huawei" class='text-dark font-lg ml-1 mb-1'
      >Huawei prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
    <router-link to="/tarieven/lg" class='text-dark font-lg ml-1 mb-1'
      >LG prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
    <router-link to="/tarieven/htc" class='text-dark font-lg ml-1 mb-1'
      >HTC prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
    <router-link to="/tarieven/sony" class='text-dark font-lg ml-1 mb-1'
      >Sony prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
    <router-link to="/tarieven/nokia" class='text-dark font-lg ml-1 mb-1'
      >Nokia prijzen
      <span class="font-xl text-warning">&rsaquo;</span></router-link
    >
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
div {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  padding: 1rem 0;

  a {
    font-size: large;
    text-align: left;
  }
}
</style>
