import { initializeApp } from "firebase/app";
import { getFirestore}  from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDNaH8aIy0UGzPnmOMpTh-0uyv9jTALaqY",
  authDomain: "q-mobile-d47a7.firebaseapp.com",
  projectId: "q-mobile-d47a7",
  storageBucket: "q-mobile-d47a7.appspot.com",
  messagingSenderId: "679829779331",
  appId: "1:679829779331:web:9b173a9c58b035dda83cb6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)