<template>
  <article class="menu">
    <!-- Animation Mobile Menu -->
    <!-- <div class="burger" @click.prevent="toggleMobilNav" v-if="iconOpen">
      <span class="bg-primary" :class="{ op1: iconOpen }"></span>
      <span class="bg-primary" :class="{ op2: iconOpen }"></span>
      <span class="bg-primary" :class="{ op3: iconOpen }"></span>
    </div> -->
    <div class="burger" @click.prevent="toggleMobilNav" v-if="mobile">
      <span :class="{ op1: !iconOpen }"></span>
      <span :class="{ op2: !iconOpen }"></span>
      <span :class="{ op3: !iconOpen }"></span>
      <!-- <span v-if="iconOpen">
        <i class="fas fa-bars fa-2x text-dark ham"></i>
      </span>
      <span v-if="!iconOpen">
        <i class="fas fa-times fa-2x text-dark ham"></i>
      </span> --> 
    </div>

    <transition name="mobileNav">
      <!-- Desktop Nav -->
      <nav class="open bg-acht" v-if="mobileNav">
        <router-link
          v-for="(b, a) in menuData"
          :to="{ name: b.link }"
          :key="a"
          class="nav-link"
        >
          {{ b.title }}
        </router-link>
      </nav>
    </transition>
    <header class="">
      <!-- Logo -->
      <div class="logo">
        <router-link to="/"
          ><img src="@/assets/Logo2.png" class="img" alt="Logo"
        /></router-link>
      </div>
      <div v-if="!mobile">
        <router-link
          class="nav-desk"
          :to="{ name: nam.link }"
          v-for="(nam, ind) in menuData"
          :key="ind"
          >{{ nam.title }}
        </router-link>
      </div>
    </header>
  </article>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      mobile: null, // mobile staand
      mobileNav: null, // Mobile nav
      windowWidth: null, // bepalen wat het innerWidth is
      isSmalScreen: null,
      windowHeight: window.top.innerHeight,
      iconOpen: null,
      scro: false,

      menuData: [
        {
          title: 'Home',
          link: 'Home',
        },
        {
          title: 'Tarieven',
          link: 'Tarieven',
        },
        {
          title: 'Accessoires',
          link: 'Acces',
        },
        {
          title: 'Contact',
          link: 'Contact',
        },
      ],
    }
  },

  components: {},

  // Live Cycle Hook
  created() {
    // Call function voor screen
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },

  // Watch
  watch: {
    $route() {
      this.mobileNav = null
      this.iconOpen = true
    },
  },

  methods: {
    // Check screen function
    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 768) {
        this.mobile = true
        this.iconOpen = true
        return
      }
      this.mobile = false
      this.mobileNav = false
      return
    },

    // Toggle function
    toggleMobilNav() {
      this.mobileNav = !this.mobileNav

      if (this.mobileNav) {
        this.iconOpen = false
      } else {
        this.iconOpen = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.menu {
  position: sticky;
  top: 0;
  z-index: 22;
  padding: 1rem;
  border-bottom: 0.3px solid $warning;
  box-shadow: 3px 2px 4px $dark;
  border-radius: 0 0 1rem 1rem;
  background-color: lighten($primary, 55%);

  @include lg {
    padding: 1rem;
  }
}

header {
  display: flex;
  justify-content: space-between;
  padding: 5px 130px;
}

.logo {
  padding: 0.2rem;

  @include lg {
    padding: 0;
  }

  img {
    width: 140px;
    height: auto;
    border-radius: 0.3rem;
    left: 2rem;
    top: 0.8rem;
    position: absolute;

    @include lg {
      width: 180px;
      top: 1.2rem;
    }
  }
}

.mobileNav-enter-active,
.mobileNav-leave-active {
  transition: 1s ease-in-out all;
}

.mobileNav-enter-from,
.mobileNav-leave-to {
  transform: translateX(200px);
}

.mobileNav-enter-to {
  transform: translateX(0);
}

.burger {
  display: block;
  font-size: 2rem;
  cursor: pointer;
  z-index: 99999 !important;
  top: 20px;
  position: fixed;
  right: 25px;
}

.nav-desk {
  display: inline-block;
  margin: 0 10px;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 5px;
  color: $primary;
}

.nav-desk:hover {
  color: $secondary;
  transition: color 0.6s ease;
}

.nav-desk.router-link-exact-active {
  color: darken($secondary, 10%);
  font-weight: bold;
}

.burger span {
  display: block;
  width: 30px;
  height: 4px;
  margin-bottom: 4px;
  background-color: $gray;
}

/* Nav Mobile */
nav {
  z-index: 9;
  width: 200px;
  height: 100vh;
  padding: 20px;
  display: block;
}

nav.open {
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-items: flex-start;
  z-index: 999;
  text-align: left;
  background-color: $gray;
}

.open .nav-link {
  font-weight: bold;
  display: flex;
  text-decoration: none;
  z-index: 9999;
  color: $warning;
  margin: 35px 0;
  text-align: left;
  padding: 0 0.5rem;
  border-bottom: $secondary 1px solid;
  font-size: large;

  &:hover {
    transform: scale(1.06);
    transition: transform 0.5s linear;
  }
}

.op1 {
  transform: rotate(44deg) translateY(11px);
  margin-left: 5px;
  transition: transform 0.6s ease-in-out;
  background-color: $light !important;
}

.op2 {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.op3 {
  transform: rotate(-44deg) translateY(-11px);
  margin-left: 5px;
  transition: transform 0.6s ease-in-out;
  background-color: $light !important;
}

@keyframes refersAnimation {
  from {
    transform: rotate(-44deg) translateY(-11px);
  }

  to {
    transform: rotate(0) translateY(0);
  }
}
</style>
