<template>
  <footer class="footer">
    <p class="text-light">
      Alle rechten voorbehouden
      <br />
      <span class="text-warning">Q-Mobile</span> &copy;2020 &dash;
      {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>
<script>
export default {
  name: 'FooTer',
}
</script>
