<template>
  <p class="lead">
    Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
    worden meerdere onderdelen vervangen.
  </p>
    <h1 class="text-warning">LG</h1>
  <article class="mb-1 px-2 art" v-if="global">
    <details v-for="l in lg" :key="l.naam">
      <summary>
        {{ l.naam }}
      </summary>
      <div class="box">
        <h1><span></span></h1>
        <h4 v-if="l.LCD">
          LCD: <span>{{ l.LCD }}</span>
        </h4>
        <h4 v-if="l.Accu">
          Accu: <span>{{ l.Accu }}</span>
        </h4>
        <h4 v-if="l.Oplaadconnector">
          Oplaad Connector: <span>{{ l.Oplaadconnector }}</span>
        </h4>
        <h4 v-if="l.Speaker">
          Speaker: <span>{{ l.Speaker }}</span>
        </h4>
        <h4 v-if="l.Luidspreker">
          Luidspreker: <span>{{ l.Luidspreker }}</span>
        </h4>
        <h4 v-if="l.Backcover">
          Backcover: <span>{{ l.Backcover }}</span>
        </h4>
        <h4 v-if="l.Camera">
          Camera: <span>{{ l.Camera }}</span>
        </h4>
        <h4 v-if="l.CameraLens">
          Camera Lens: <span>{{ l.CameraLens }}</span>
        </h4>
        <h4 v-if="l.VoorCamera">
          Voor Camera: <span>{{ l.VoorCamera }}</span>
        </h4>
        <h4 v-if="l.Wifi">
          Wifi: <span>{{ l.Wifi }}</span>
        </h4>
        <h4 v-if="l.Antenne">
          Antenne: <span>{{ l.Antenne }}</span>
        </h4>
      </div>
    </details>
  </article>
  <article v-if="!global" class="my-2 px-2">
    <table>
      <tr>
        <th>Merk</th>
        <th>LCD</th>
        <th>Accu</th>
        <th>Oplaad Connector</th>
        <th>Speaker</th>
        <th>Luidspreker</th>
        <th>Backcover</th>
        <th>Camera</th>
        <th>Camera Lens</th>
        <th>Voor Camera</th>
        <th>WiFi</th>
        <th>Antenne</th>
      </tr>
      <tr v-for="hu in lg" :key="hu.naam">
        <td class="py-1" v-if="hu.naam">{{ hu.naam }}</td>
        <td v-if="!hu.naam">n.v.t</td>

        <td v-if="hu.LCD">{{ hu.LCD }}</td>
        <td v-if="!hu.LCD">n.v.t</td>

        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>

        <td v-if="hu.Speaker">{{ hu.Speaker }}</td>
        <td v-if="!hu.Speaker">n.v.t</td>

        <td v-if="hu.Luidspreker">{{ hu.Luidspreker }}</td>
        <td v-if="!hu.Luidspreker">n.v.t</td>
        
        <td v-if="hu.Backcover">{{ hu.Backcover }}</td>
        <td v-if="!hu.Backcover">n.v.t</td>

        <td v-if="hu.Camera">{{ hu.Camera }}</td>
        <td v-if="!hu.Camera">n.v.t</td>

        <td v-if="hu.CameraLens">{{ hu.CameraLens }}</td>
        <td v-if="!hu.CameraLens">n.v.t</td>


        <td v-if="hu.VoorCamera">{{ hu.VoorCamera }}</td>
        <td v-if="!hu.VoorCamera">n.v.t</td>

        <td v-if="hu.Wifi">{{ hu.Wifi }}</td>
        <td v-if="!hu.Wifi">n.v.t</td>

        <td v-if="hu.Antenne">{{ hu.Antenne }}</td>
        <td v-if="!hu.Antenne">n.v.t</td>
      </tr>
    </table>
  </article>

  <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
    >Alle merken</router-link
  >
  <br />
  <br />
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.art {
  @include md {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @include lg {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
</style>
