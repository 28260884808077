import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Mix from '@/mixins'
import '../public/css/main.css'

createApp(App).use(store).mixin(Mix).use(router).mount('#app')



