<template>
  <p class="lead">
    Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
    worden meerdere onderdelen vervangen.
  </p>

  <h1 class="text-warning">Sony</h1>

  <article class="mb-1 px-2 art" v-if="global">
    <details v-for="so in sony" :key="so.naam">
      <summary>
        {{ so.naam }}
      </summary>
      <div class="box">
        <h4 v-if="so.Glas">
          Glas: <span>{{ so.Glas }}</span>
        </h4>
        <h4 v-if="so.LCD">
          LCD: <span>{{ so.LCD }}</span>
        </h4>
        <h4 v-if="so.Accu">
          Accu: <span>{{ so.Accu }}</span>
        </h4>
        <h4 v-if="so.Oplaadconnector">
          Oplaadconnector: <span>{{ so.Oplaadconnector }}</span>
        </h4>
        <h4 v-if="so.Speaker">
          Speaker: <span>{{ so.Speaker }}</span>
        </h4>
        <h4 v-if="so.Luidspreker">
          Luidspreker: <span>{{ so.Luidspreker }}</span>
        </h4>
        <h4 v-if="so.Backcover">
          Backcover: <span>{{ so.Backcover }}</span>
        </h4>
      </div>
    </details>
  </article>

  <article v-if="!global" class="my-2 px-2">
    <table>
      <tr>
        <th>Merk</th>
        <th>Glas</th>
        <th>LCD</th>
        <th>Accu</th>
        <th>Oplaad Connector</th>
        <th>Speaker</th>
        <th>Luidspreker</th>
        <th>Backcover</th>
      </tr>
      <tr v-for="hu in sony" :key="hu.naam">
        <td class="py-1" v-if="hu.naam">{{ hu.naam }}</td>
        <td v-if="!hu.naam">n.v.t</td>
        <td v-if="hu.Glas">{{ hu.Glas }}</td>
        <td v-if="!hu.Glas">n.v.t</td>
        <td v-if="hu.LCD">{{ hu.LCD }}</td>
        <td v-if="!hu.LCD">n.v.t</td>
        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>
        <td v-if="hu.Speaker">{{ hu.Speaker }}</td>
        <td v-if="!hu.Speaker">n.v.t</td>
        <td v-if="hu.Luidspreker">{{ hu.Luidspreker }}</td>
        <td v-if="!hu.Luidspreker">n.v.t</td>
        <td v-if="hu.Backcover">{{ hu.Backcover }}</td>
        <td v-if="!hu.Backcover">n.v.t</td>
      </tr>
    </table>
  </article>
  <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
    >Alle merken</router-link
  >

  <br />
  <br />
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.art {
  @include md {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @include lg {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
</style>
