<template>
  <div class="mb-1 px-2">
    <p class="lead">
      Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
      worden meerdere onderdelen vervangen.
    </p>
    <h1 class='text-warning'>iPhones</h1>
    <IPhones class="iphones" />
    <br />
    <hr />
    <h1 class='text-warning'>iPads</h1>
    <IPads class="ipads" />
    <br />
    <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
      >Alle merken</router-link
    >
  </div>
</template>

<script>
import IPhones from './iPhones.vue'
import IPads from './iPads.vue'
export default {
  components: { IPhones, IPads },
}
</script>

<style lang="scss" scoped>
div {
  @include md {
    padding: 0;
    .iphones,
    .ipads {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.3rem;
    }
  }
  @include lg {
    padding: 0;
    .iphones,
    .ipads {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3rem;
    }
  }
}
</style>
