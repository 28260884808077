<template>
  <article class="grid">
    <section>
      <h3 class="h2">Altijd goede service</h3>
      <!-- <i class="fa fa-check"></i> -->
      <img
        src="@/assets/shapes/undraw_bug_fixing_oc-7-a.svg"
        alt=""
        class="imgIcon"
      />
    </section>
    <section>
      <h3 class="h2">Software & Hardware</h3>
      <img
        src="@/assets/shapes/undraw_maintenance_re_59vn.svg"
        alt=""
        class="imgIcon"
      />
    </section>
    <section>
      <h3 class="h2">Alle soort apparaten</h3>
      <img
        src="@/assets/shapes/undraw_mobile_devices_k1ok.svg"
        alt=""
        class="imgIcon"
      />
    </section>
  </article>
</template>

<script>
export default {
  name: 'IcnOs',
}
</script>

<style scoped lang="scss">
.grid {
  width: 100%;
  margin: 20px auto;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;

  @include md {
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem;
  }
  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }
}

section {
  display: block;
  margin: 10px auto;
}

h3 {
  font-size: 26px;
  margin-bottom: 5px;
}

.fa {
  font-size: 50px;
  color: #cfa900;
}

.imgIcon {
  width: 250px;
  height: 200px;
  margin: 0 auto;
}
</style>
