const productsData = {
  iphones: [
    {
      naam: 'iPhone 4(S)',
      SchermOrigineel: '€39,99',
      SchermAAA: '€19,99',
      Accu: '€24,99',
      Oplaadconnector: '€24,99',
      Camera: '€224,99',
      CameraLens: '€24,99',
      HomeKnop: '€24,99',
      Speaker: '€19,99',
    },
    {
      naam: 'iPhone 5/5c',
      SchermOrigineel: '€44,99',
      SchermAAA: '€29,99',
      Accu: '€29,99',
      Oplaadconnector: '€24,99',
      Camera: '€224,99',
      CameraLens: '€24,99',
      HomeKnop: '€24,99',
      Speaker: '€24,99',
    },
    {
      naam: 'iPhone 5S/5SE',
      SchermOrigineel: '€54,99',
      SchermAAA: '€34,99',
      Accu: '€29,99',
      Oplaadconnector: '€34,99',
      Camera: '€24,99',
      CameraLens: '€34,99',
      HomeKnop: '€24,99',
      Speaker: '€24,99',
    },
    {
      naam: 'iPhone 6',
      SchermOrigineel: '€59,99',
      SchermAAA: '€44,99',
      Accu: '€34,99',
      Oplaadconnector: '€39,99',
      Camera: '€39,99',
      CameraLens: '€29,99',
      HomeKnop: '€24,99',
      Speaker: '€24,99',
    },
    {
      naam: 'iPhone 6 Plus',
      SchermOrigineel: '€79,99',
      SchermAAA: '€59,99',
      Accu: '€39,99',
      Oplaadconnector: '€39,99',
      Camera: '€39,99',
      CameraLens: '€29,99',
      HomeKnop: '€29,99',
      Speaker: '€29,99',
    },
    {
      naam: 'iPhone 6S Plus',
      SchermOrigineel: '€79,99',
      SchermAAA: '€64,99',
      Accu: '€39,99',
      Oplaadconnector: '€39,99',
      Camera: '€39,99',
      CameraLens: '€29,99',
      HomeKnop: '€29,99',
      Speaker: '€34,99',
    },
    {
      naam: 'iPhone 7',
      SchermOrigineel: '€89,99',
      SchermAAA: '€64,99',
      Accu: '€39,99',
      Oplaadconnector: '€49,99',
      Camera: '€59,99',
      CameraLens: '€39,99',
      HomeKnop: '€49,99*',
      Speaker: '€39,99',
    },
    {
      naam: 'iPhone 7 Plus',
      SchermOrigineel: '€89,99',
      SchermAAA: '€69,99',
      Accu: '€49,99',
      Oplaadconnector: '€49,99',
      Camera: '€109,99',
      CameraLens: '€49,99',
      HomeKnop: '€49,99*',
      Speaker: '€39,99',
    },
    {
      naam: 'iPhone 8',
      SchermOrigineel: '€99,99',
      SchermAAA: '€79,99',
      Accu: '€49,99',
      Oplaadconnector: '€49,99',
      Camera: '€99,99',
      CameraLens: '€49,99',
      HomeKnop: '€49,99*',
      Speaker: '€39,99',
    },
    {
      naam: 'iPhone 8 Plus',
      SchermOrigineel: '€109,99',
      SchermAAA: '€89,99',
      Accu: '€59,99',
      Oplaadconnector: '€59,99',
      Camera: '€99,99',
      CameraLens: '€49,99',
      HomeKnop: '€49,99*',
      Speaker: '€49,99',
    },
    {
      naam: 'iPhone X',
      SchermOrigineel: '€189,99',
      SchermAAA: '€129,99',
      Accu: '€59,99',
      Oplaadconnector: '€59,99',
      Camera: '€109,99',
      CameraLens: '€49,99',
      Speaker: '€59,99',
    },
    {
      naam: 'iPhone XS',
      SchermOrigineel: '€199,99',
      SchermAAA: '€129,99',
      Accu: '€59,99',
      Oplaadconnector: '€69,99',
      Camera: '€99,99',
      CameraLens: '€59,99',
      Speaker: '€59,99',
    },
    {
      naam: 'iPhone XS Max',
      SchermOrigineel: '€219,99',
      SchermAAA: '€149,99',
      Accu: '€59,99',
      Oplaadconnector: '€69,99',
      Camera: '€109,99',
      CameraLens: '€59,99',
      Speaker: '€59,99',
    },
    {
      naam: 'iPhone XR',
      SchermOrigineel: '€149,99',
      SchermAAA: '€109,99',
      Accu: '€59,99',
      Oplaadconnector: '€59,99',
      Camera: '€69,99',
      CameraLens: '€49,99',
      Speaker: '€59,99',
    },
    {
      naam: 'iPhone 11',
      SchermOrigineel: '€169,99',
      SchermAAA: '€119,99',
      Accu: '€99,99',
      Oplaadconnector: '€89,99',
      Camera: '€109,99',
      CameraLens: '€79,99',
      Speaker: '€69,99',
    },
    {
      naam: 'iPhone 11 Pro',
      SchermOrigineel: '€249,99',
      SchermAAA: '€139,99',
      Accu: '€99,99',
      Oplaadconnector: '€89,99',
      Camera: '€129,99',
      CameraLens: '€89,99',
      Speaker: '€89,99',
    },
    {
      naam: 'iPhone 11 Pro Max',
      SchermOrigineel: '€229,99',
      SchermAAA: '€179,95',
      Accu: '€99,99',
      Oplaadconnector: '€89,99',
      Camera: '€129,99',
      CameraLens: '€89,99',
      Speaker: '€89,99',
    },
    {
      naam: 'iPhone 12',
      SchermOrigineel: '€199,99',
      Accu: '€99,99',
      Oplaadconnector: '€89,99',
      Camera: '€124,99',
      Speaker: '€69,99',
    },
    {
      naam: 'iPhone 12 Mini',
      SchermOrigineel: '€199,99',
      Accu: '€89,99',
      Oplaadconnector: '€89,99',
      Camera: '€125,99',
      Speaker: '€69,99',
    },
    {
      naam: 'iPhone 12 Pro',
      SchermOrigineel: '€199,99',
      Accu: '€89,99',
      Oplaadconnector: '€89,99',
      Camera: '€124,99',
      Speaker: '€99,99',
    },
    {
      naam: 'iPhone 12 Pro Max',
      SchermOrigineel: '€269,99',
      Accu: '€89,99',
      Oplaadconnector: '€89,99',
      Camera: '€124,99',
      Speaker: '€69,99',
    },
    {
      naam: 'iPhone 13',
      SchermOrigineel: '€339,99',
      Accu: '€99,99',
      Oplaadconnector: '€89,99',
      Camera: '€124,99',
      Speaker: '€99,99',
    },
    {
      naam: 'iPhone 13 Mini',
      SchermOrigineel: '€249,99',
      Accu: '€99,99',
      Oplaadconnector: '€89,99',
      Camera: '€124,99',
      Speaker: '€99,99',
    },
    {
      naam: 'iPhone 13 Pro',
      SchermOrigineel: '€339,99',
      Accu: '€99,99',
      Oplaadconnector: '€99,99',
      Camera: '€124,99',
      Speaker: '€109,99',
    },
    {
      naam: 'iPhone 13 Pro Max',
      SchermOrigineel: '€379,99',
      Accu: '€99,99',
      Oplaadconnector: '€99,99',
      Camera: '€124,99',
      Speaker: '€109,99',
    },
  ],

  ipads: [
    {
      naam: 'iPad 2',
      Glas: '€59,99',
      Oplaadconnector: '€59,99',
      Accu: '€49,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad 3',
      Glas: '€69,99',
      Oplaadconnector: '€59,99',
      Accu: '€49,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad 4',
      Glas: '€69,99',
      Oplaadconnector: '€59,99',
      Accu: '€49,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Air',
      Glas: '€89,99',
      Oplaadconnector: '€79,99',
      Accu: '€69,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Air 2',
      Glas: '€189,99',
      Oplaadconnector: '€89,99',
      Accu: '€69,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Mini',
      Glas: '€79,99',
      Oplaadconnector: '€89,99',
      Accu: '€89,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Mini 2',
      Glas: '€79,99',
      Oplaadconnector: '€89,99',
      Accu: '€89,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Mini 3',
      Glas: '€89,99',
      Oplaadconnector: '€89,99',
      Accu: '€89,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Mini 4',
      Glas: '€199,99',
      Oplaadconnector: '€89,99',
      Accu: '€89,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Mini 5',
      Glas: '€229,99',
      Oplaadconnector: '€149,99',
      Accu: '€99,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad 2017',
      Glas: '€89,99',
      Oplaadconnector: '€79,99',
      Accu: '€79,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad 2018',
      Glas: '€119,99',
      Oplaadconnector: '€89,99',
      Accu: '€79,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad 2019 10.2',
      Glas: '€149,99',
      Oplaadconnector: '€109,99',
      Accu: '€99,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Air 2019',
      Glas: '€249,99',
      Oplaadconnector: '€119,99',
      Accu: '€99,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Pro',
      Glas: '€219,99',
      Oplaadconnector: '€149,99',
      Accu: '€119,99',
      GlasProtector: '€19,99',
    },
    {
      naam: 'iPad Pro 11',
      Glas: '€499,99',
      Oplaadconnector: '€199,99',
      Accu: '€179,99',
      GlasProtector: '€24,99',
    },
    {
      naam: 'iPad Pro 12.9',
      Glas: '€649,99',
      Oplaadconnector: '€199,99',
      Accu: '€179,99',
      GlasProtector: '€24,99',
    },
  ],

  samsungs: [
    {
      naam: 'S6',
      Scherm: '€149,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€34,99',
      Accu: '€49,99',
      Oplaadconnector: '€59,99*',
      SoftwareAccount: '€49,99',
      Camera: '€49,99',
      CameraLens: '€24,99',
    },
    {
      naam: 'S6 Edge',
      Scherm: '€149,99',
      BackcoverOrigineel: '€49,99',
      BackcoverAAA: '€34,99',
      Accu: '€49,99',
      Oplaadconnector: '€59,99*',
      SoftwareAccount: '€49,99',
      Camera: '€49,99',
      CameraLens: '€29,99',
    },
    {
      naam: 'S7',
      Scherm: '€139,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€34,99',
      Accu: '€59,99',
      Oplaadconnector: '€69,99*',
      SoftwareAccount: '€49,99',
      Camera: '€49,99',
      CameraLens: '€29,99',
    },
    {
      naam: 'S7 Edge',
      Scherm: '€199,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€34,99',
      Accu: '€59,99',
      Oplaadconnector: '€69,99*',
      SoftwareAccount: '€49,99',
      Camera: '€69,99',
      CameraLens: '€34,99',
    },
    {
      naam: 'S8',
      Scherm: '€219,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€39,99',
      Accu: '€59,99',
      Oplaadconnector: '€59,99',
      SoftwareAccount: '€59,99',
      Camera: '€69,99',
      CameraLens: '€34,99',
    },
    {
      naam: 'S8 Plus',
      Scherm: '€239,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€39,99',
      Accu: '€59,99',
      Oplaadconnector: '€59,99',
      SoftwareAccount: '€59,99',
      Camera: '€69,99',
      CameraLens: '€34,99',
    },
    {
      naam: 'S9',
      Scherm: '€249,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€39,99',
      Accu: '€64,99',
      Oplaadconnector: '€59,99',
      SoftwareAccount: '€59,99',
      Camera: '€69,99',
      CameraLens: '€34,99',
    },
    {
      naam: 'S9 Plus',
      Scherm: '€269,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€39,99',
      Accu: '€64,99',
      Oplaadconnector: '€69,99',
      SoftwareAccount: '€59,99',
      Camera: '€69,99',
      CameraLens: '€34,99',
    },
    {
      naam: 'S10E',
      Scherm: '€199,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€64,99',
      Oplaadconnector: '€99,99*',
      SoftwareAccount: '€59,99',
      Camera: '€79,99',
      CameraLens: '€39,99',
    },
    {
      naam: 'S10',
      Scherm: '€269,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€64,99',
      Oplaadconnector: '€99,99*',
      SoftwareAccount: '€59,99',
      Camera: '€89,99',
      CameraLens: '€39,99',
    },
    {
      naam: 'S10 Plus',
      Scherm: '€279,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€64,99',
      Oplaadconnector: '€109,99*',
      SoftwareAccount: '€59,99',
      Camera: '€89,99',
      CameraLens: '€39,99',
    },
    {
      naam: 'S10 5G',
      Scherm: '€319,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€64,99',
      Oplaadconnector: '€119,99*',
      SoftwareAccount: '€59,99',
      Camera: '€129,99',
      CameraLens: '€39,99',
    },
    {
      naam: 'S10 Lite',
      Scherm: '€179,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€59,99',
      Oplaadconnector: '€59,99*',
    },
    {
      naam: 'S20',
      Scherm: '€269,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€79,99*',
    },
    {
      naam: 'S20 Plus',
      Scherm: '€269,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€79,99*',
    },
    {
      naam: 'S20 Ultra',
      Scherm: '€299,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€79,99*',
    },
    {
      naam: 'S20 FE',
      Scherm: '€169,99',
      BackcoverOrigineel: '€79,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€79,99*',
    },
    {
      naam: 'S21',
      Scherm: '€249,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
    },
    {
      naam: 'S21 Plus',
      Scherm: '€249,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
    },
    {
      naam: 'S21 Ultra',
      Scherm: '€399,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
    },
    {
      naam: 'S22',
      Scherm: '€139,99',
      BackcoverOrigineel: '€39,99',
      BackcoverAAA: '€49,99',
      Accu: '€49,99',
      Oplaadconnector: '€49,99',
      Camera: '€79,99'
    },
    {
      naam: 'S22 Plus',
      Scherm: '€149,99',
      BackcoverOrigineel: '€39,99',
      BackcoverAAA: '€49,99',
      Accu: '€49,99',
      Oplaadconnector: '€49,99',
      Camera: '€79,99'
    },
    {
      naam: 'S22 Ultra',
      Scherm: '€249,99',
      BackcoverOrigineel: '€39,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€49,99',
      Camera: '€79,99'
    },
    {
      naam: 'Note 20 Ultra',
      Scherm: '€319,00',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Camera: '€49,99'
    },

    {
      naam: 'Note 20',
      Scherm: '€249,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€69',
      Camera: '€49,99'
    },

    {
      naam: 'Note 10 plus',
      Scherm: '€149,99',
      BackcoverOrigineel: '€69,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€69',
      Camera: '€49,99'
    },
    {
      naam: 'Note 10',
      Scherm: '€149,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€44,99',
      Accu: '€79,99',
      Oplaadconnector: '€69',
      Camera: '€49,99'
    },
    {
      naam: 'Note 9',
      Scherm: '€209,99',
      BackcoverOrigineel: '€59,99',
      BackcoverAAA: '€44,99',
      Accu: '€59,99',
      Oplaadconnector: '€49',
      Camera: '€49,99'
    },
    {
      naam: 'Note 8',
      Scherm: '€139,99',
      BackcoverOrigineel: '€49,99',
      BackcoverAAA: '€44,99',
      Accu: '€49,99',
      Oplaadconnector: '€49',
      Camera: '€49,99'
    },
    {
      naam: 'Note 5',
      Scherm: '€129,99',
      BackcoverOrigineel: '€49,99',
      BackcoverAAA: '€44,99',
      Accu: '€49,99',
      Oplaadconnector: '€49',
      Camera: '€49,99'
    },
    {
      naam: 'A3',
      LCD: '€99',
      BackcoverOrigineel: '€59,99',
      Oplaadconnector: '€69',
      Accu: '€69,99',
      Luidspreker: '€69,99',
      Camera: '€89,99'
    },
    {
      naam: 'A5',
      LCD: '€109',
      BackcoverOrigineel: '€59,99',
      Oplaadconnector: '€49',
      Accu: '€39,99',
      Luidspreker: '€49,99',
      Camera: '€59,99'
    },
    {
      naam: 'A6 (2018)',
      LCD: '€99',
      BackcoverOrigineel: '€54,99',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€59,99'
    },
    {
      naam: 'A7 (2018)',
      LCD: '€109',
      BackcoverOrigineel: '€44,99',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€59,99'

    },

    {
      naam: 'A8',
      LCD: '€119',
      Oplaadconnector: '€49',
      Accu: '€59,99',
      Luidspreker: '€49,99',
      Camera: '€59'
    },
    {
      naam: 'A9',
      LCD: '€119',
      Oplaadconnector: '€69',
      Accu: '€69,99',
      Luidspreker: '€69,99',
      Camera: '€69'
    },

    {
      naam: 'A10',
      LCD: '€89',
      Oplaadconnector: '€69',
      Accu: '€89,99',
      Luidspreker: '€69,99',
      Camera: '€89'
    },
    {
      naam: 'A12',
      LCD: '€99',
      Oplaadconnector: '€49',
    },

    {
      naam: 'A20 S',
      LCD: '€89',
      Oplaadconnector: '€39',
    },
    {
      naam: 'A20 E',
      LCD: '€89',
      Oplaadconnector: '€69',
      Accu: '€69,99',
      Luidspreker: '€69,99',
      Camera: '€89'
    },
    {
      naam: 'A21 S',
      LCD: '€89',
      Oplaadconnector: '€39',
    },
    {
      naam: 'A30 S',
      LCD: '€189',
      Oplaadconnector: '€49',
    },
    {
      naam: 'A31',
      LCD: '€139',
      Oplaadconnector: '€49',
    },
    {
      naam: 'A32 4G',
      LCD: '€139',
      Oplaadconnector: '€49',
    },
    {
      naam: 'A32 5G',
      LCD: '€139',
      Oplaadconnector: '€49',
    },
    {
      naam: 'A40',
      LCD: '€129',
      Oplaadconnector: '€49',
    },
    {
      naam: 'A40',
      LCD: '€89',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€59'
    },
    {
      naam: 'A41',
      LCD: '€99',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€59'
    },
    {
      naam: 'A42',
      LCD: '€89',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€59'
    },

    {
      naam: 'A50',
      LCD: '€89',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€49'
    },
    {
      naam: 'A51',
      LCD: '€59',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€49'
    },
    {
      naam: 'A52',
      LCD: '€59',
      Oplaadconnector: '€49',
      Accu: '€39,99',
      Luidspreker: '€49,99',
      Camera: '€49'
    },
    {
      naam: 'A70',
      LCD: '€89',
      Oplaadconnector: '€49',
      Accu: '€39,99',
      Luidspreker: '€49,99',
      Camera: '€59'
    },
    {
      naam: 'A71',
      LCD: '€99',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€49'
    },

    {
      naam: 'A72',
      LCD: '€59',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
      Camera: '€49'
    },
    {
      naam: 'A80',
      LCD: '€89',
      Oplaadconnector: '€49',
      Accu: '€49,99',
      Luidspreker: '€49,99',
    },


    {
      naam: 'J1',
      LCD: '€69',
      Accu: '€69',
    },
    {
      naam: 'J3',
      LCD: '€79',
      Accu: '€69',
    },
    {
      naam: 'J5',
      LCD: '€69',
      Accu: '€49',
    },
    {
      naam: 'J6 (2018)',
      LCD: '€99',
      Accu: '€69',
    },

    {
      naam: 'J6+ (2018)',
      LCD: '€89',
      Oplaadconnector: '€49',
    },
    {
      naam: 'J7',
      LCD: '€89',
      Accu: '€69',
    },
  ],

  notes: [
    {
      naam: 'Note 2',
      Glas: '€49',
      LCD: '€149',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Note 3',
      Glas: '€59',
      LCD: '€199',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Note 4',
      LCD: '€199',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Note 8',
      LCD: '€329',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Note 9',
      LCD: '€349',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Xcover 3',
      Glas: '€49',
      LCD: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Xcover 4',
      Glas: '€59',
      LCD: '€59',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Note 10',
      LCD: '€349',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Note 10 Plus',
      LCD: '€349',
      Oplaadconnector: '€59',
    },
    {
      naam: 'Note 20',
      LCD: '€289',
      Oplaadconnector: '€79',
    },
    {
      naam: 'Note 20 Plus',
      LCD: '€319',
      Oplaadconnector: '€79',
    },
    {
      naam: 'Note 20 Ultra',
      LCD: '€319',
      Oplaadconnector: '€79',
    },
  ],

  tab: [
    {
      naam: 'Tab A 7.0 (T280)',
      Glas: '€30',
      LCD: '€49',
      Accu: '€49',
      Oplaadconnector: '€29',
      Speaker: '€29',
      Luidspreker: '€29',
    },
    {
      naam: 'Tab A (T550)',
      Glas: '€79',
      LCD: '€79',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
    },
    {
      naam: 'Tab A 10.1 (T580)',
      LCD: '€199',
      Accu: '€49',
      Oplaadconnector: '€49',
      Speaker: '€39',
      Luidspreker: '€39',
    },
    {
      naam: 'Tab E 9.6',
      LCD: '€79',
      Accu: '€49',
      Oplaadconnector: '€49',
      Speaker: '€29',
      Luidspreker: '€29',
    },
    {
      naam: 'Tab S2 9.7',
      LCD: '€249',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Tab S2 8.0',
      LCD: '€199',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Tab 4 10.1 (T530)',
      Glas: '€69',
      LCD: '€59',
      Accu: '€49',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Tab 4 7.0 (T230)',
      Glas: '€39',
      LCD: '€39',
      Accu: '€39',
      Oplaadconnector: '€29',
    },
    {
      naam: 'Tab 3 10.1 (P5200/P5210)',
      Glas: '€59',
      LCD: '€59',
      Accu: '€49',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Tab 3 7.1 (T210)',
      Glas: '€39',
      LCD: '€39',
      Accu: '€39',
      Oplaadconnector: '€29',
    },
    {
      naam: 'Tab A 8.0',
      Accu: '€49',
      Oplaadconnector: '€29',
    },
    {
      naam: 'Tab A 9.0',
      Accu: '€49',
      Oplaadconnector: '€29',
    },
    {
      naam: 'Tab 3 Lite (T110)',
      Glas: '€39',
      LCD: '€39',
      Accu: '€39',
      Oplaadconnector: '€29',
    },
    {
      naam: 'Tab Note 10.1',
      LCD: '€249',
      Accu: '€49',
      Oplaadconnector: '€39',
    },
  ],

  huwai: [
    {
      naam: 'Huawei P8',
      LCD: '€89',
      Accu: '€35',
      Oplaadconnector: '€35',
    },
    {
      naam: 'Huawei P8 Lite',
      LCD: '€69',
      Accu: '€35',
      Oplaadconnector: '€35',
    },
    {
      naam: 'Huawei Lite 2017',
      LCD: '€99',
      Accu: '€35',
      Oplaadconnector: '€35',
    },
    {
      naam: 'Huawei P9',
      LCD: '€99',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei P9 Lite',
      LCD: '€89',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei P10',
      LCD: '€99',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei P10 Lite',
      LCD: '€89',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei P10 Plus',
      LCD: '€199',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei P20',
      LCD: '€169',
      Accu: '€59',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei P20 Lite',
      LCD: '€89',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei P10 Pro',
      LCD: '€299',
      Accu: '€59',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei Psmart',
      LCD: '€89',
      Accu: '€35',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei Psmart Pls',
      LCD: '€89',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei Psmart 2019',
      LCD: '€89',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei Mate 7',
      LCD: '€119',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei Mate 8',
      LCD: '€119',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei Mate 9',
      LCD: '€109',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'Huawei Mate 9 Plus',
      LCD: '€129',
    },
    {
      naam: 'Huawei Mate 10',
      LCD: '€159',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei Mate 10 Lite',
      LCD: '€109',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei Mate 10 Pro',
      LCD: '€169',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei Mate 20',
      LCD: '€149',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei Mate 20 Lite',
      LCD: '€99',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei Mate 20 Pro',
      LCD: '€269',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei P30',
      LCD: '€299',
      Accu: '€49',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei P30 Lite',
      LCD: '€109',
      Accu: '€39',
      Oplaadconnector: '€49',
    },
    {
      naam: 'Huawei P30 Pro',
      LCD: '€349',
      Accu: '€59',
      Oplaadconnector: '€49',
    },
  ],

  lg: [
    {
      naam: 'LG G2',
      LCD: '€89',
      Accu: '€35',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€25',
      Camera: '€29',
      CameraLens: '€20',
      VoorCamera: '€20',
      Wifi: '€20',
      Antenne: '€20',
    },
    {
      naam: 'LG G3',
      LCD: '€99',
      Oplaadconnector: '€35',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€20',
      Camera: '€39',
      CameraLens: '€20',
      VoorCamera: '€29',
    },

    {
      naam: 'LG G4',
      LCD: '€110',
      Oplaadconnector: '€35',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€20',
      Camera: '€39',
      CameraLens: '€20',
      VoorCamera: '€29',
    },
    {
      naam: 'LG G5',
      LCD: '€139',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€35',
      Camera: '€59',
      CameraLens: '€29',
      VoorCamera: '€29',
    },
    {
      naam: 'LG G6',
      LCD: '€199',
    },
    {
      naam: 'LG K4',
      LCD: '€69',
      Oplaadconnector: '€29',
    },
    {
      naam: 'LG K5',
      LCD: '€69',
      Oplaadconnector: '€29',
    },
    {
      naam: 'LG K7',
      LCD: '€69',
      Oplaadconnector: '€29',
    },
    {
      naam: 'LG K8',
      LCD: '€69',
      Oplaadconnector: '€29',
    },
    {
      naam: 'LG K10',
      LCD: '€79',
      Oplaadconnector: '€29',
    },
    {
      naam: 'LG X Screen',
      LCD: '€129',
    },
    {
      naam: 'LG Nexus 5',
      LCD: '€89',
      Oplaadconnector: '€39',
      Speaker: '€39',
      Luidspreker: '€39',
    },
    {
      naam: 'LG Nexus 5X',
      LCD: '€129',
    },
  ],

  htc: [
    {
      naam: 'HTC 10',
      LCD: '€199',
    },
    {
      naam: 'HTC M8',
      LCD: '€99',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'HTC M8 Mini',
      LCD: '€99',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'HTC M9',
      LCD: '€99',
      Accu: '€39',
      Oplaadconnector: '€39',
    },
    {
      naam: 'HTC M10',
      LCD: '€99',
    },
    {
      naam: 'HTC A9',
      LCD: '€179',
    },
    {
      naam: 'HTC U11',
      LCD: '€199',
    },
  ],

  sony: [
    {
      naam: 'Sony XZ',
      LCD: '€149',
      Accu: '€49',
      Oplaadconnector: '€49',
      Speaker: '€39',
      Luidspreker: '€39',
      Backcover: '€35',
    },
    {
      naam: 'Sony C5 Ultra',
      LCD: '€139',
      Accu: '€49',
      Oplaadconnector: '€49',
      Speaker: '€39',
      Luidspreker: '€39',
      Backcover: '€35',
    },
    {
      naam: 'Sony E5',
      LCD: '€119',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€39',
    },
    {
      naam: 'Sony M5',
      LCD: '€139',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€29',
    },
    {
      naam: 'Sony XA',
      LCD: '€129',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€29',
    },
    {
      naam: 'Sony X',
      LCD: '€129',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€29',
    },
    {
      naam: 'Sony M4',
      LCD: '€99',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€29',
    },
    {
      naam: 'Sony Z3',
      LCD: '€110',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€39',
      Luidspreker: '€39',
      Backcover: '€29',
    },
    {
      naam: 'Sony Z3 Compact',
      LCD: '€99',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€39',
      Luidspreker: '€39',
      Backcover: '€35',
    },
    {
      naam: 'Sony Z5',
      LCD: '€129',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€39',
      Luidspreker: '€39',
      Backcover: '€29',
    },
    {
      naam: 'Sony Z5 Mini',
      LCD: '€119',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€39',
      Luidspreker: '€39',
      Backcover: '€29',
    },
    {
      naam: 'Sony M2',
      Glas: '€49',
      LCD: '€49',
      Accu: '€39',
      Oplaadconnector: '€35',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€29',
    },
    {
      naam: 'Sony Z5 Premium',
      LCD: '€139',
      Accu: '€49',
      Oplaadconnector: '€39',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€35',
    },
    {
      naam: 'Sony Z2',
      LCD: '€99',
      Accu: '€39',
      Oplaadconnector: '€35',
      Speaker: '€29',
      Luidspreker: '€29',
      Backcover: '€25',
    },
    // {
    //   naam: 'Sony Z5 Compact',
    //   Glas: '€49',
    //   LCD: '€49',
    //   Accu: '€39',
    //   Oplaadconnector: '€35',
    //   Speaker: '€29',
    //   Luidspreker: '€29',
    //   Backcover: '€29',
    // },
  ],

  nokia: [
    {
      naam: 'Nokia 3',
      LCD: '€89',
    },
    {
      naam: 'Nokia 5',
      LCD: '€99',
    },
    {
      naam: 'Nokia 6',
      LCD: '€110',
    },
    {
      naam: 'Nokia 520',
      LCD: '€39',
    },
    {
      naam: 'Lumia 820',
      LCD: '€59',
    },
    {
      naam: 'Lumia 925',
      LCD: '110',
    },
    {
      naam: 'Lumia 950',
      LCD: '€179',
    },
    {
      naam: 'Lumia 950 XL',
      LCD: '€199',
    },
    {
      naam: 'Lumia 650',
      LCD: '€99',
    },
  ],
}

module.exports = { productsData }
