<template>
	<article>
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2422.0576077672154!2d6.037880276439836!3d52.622803072088274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c877259e375245%3A0x5507044db09826bf!2sLangestraat%2058%2C%208281%20AM%20Genemuiden!5e0!3m2!1snl!2snl!4v1712684988478!5m2!1snl!2snl"
			width="90%"
			height="350"
			style="border: 0; margin: 0 auto"
			allowfullscreen="true"
			loading="lazy"
			referrerpolicy="no-referrer-when-downgrade"
		></iframe>
	</article>
</template>

<script>
	export default {
		name: "Kaart",
	}
</script>

<style
	scoped
	lang="scss"
>
	article {
		display: flex;
		padding: 20px 0;
	}
</style>
