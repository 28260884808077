<template>
  <p class="lead">
    Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
    worden meerdere onderdelen vervangen.
  </p>
  <h1 class="text-warning">HTC</h1>
  <article class="mb-1 px-2 art" v-if="global">
    <details v-for="ht in htc" :key="ht.naam">
      <summary>
        {{ ht.naam }}
      </summary>
      <div class="box">
        <h4 v-if="ht.LCD">
          LCD: <span>{{ ht.LCD }}</span>
        </h4>
        <h4 v-if="ht.Accu">
          Accu: <span>{{ ht.Accu }}</span>
        </h4>
        <h4 v-if="ht.Oplaadconnector">
          Oplaadconnector: <span>{{ ht.Oplaadconnector }}</span>
        </h4>
      </div>
    </details>
  </article>

  <article v-if="!global" class="my-2 px-2">
    <table>
      <tr>
        <th>Merk</th>
        <th>LCD</th>
        <th>Accu</th>
        <th>Oplaadconnector</th>
      </tr>
      <tr v-for="hu in htc" :key="hu.naam">
        <td class="py-1">{{ hu.naam }}</td>
        <td>{{ hu.LCD }}</td>
        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>
      </tr>
    </table>
    <br />
  </article>
  <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
    >Alle merken</router-link
  >

  <br />
  <br />
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.art {
  @include md {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @include lg {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
</style>
