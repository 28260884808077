<template>
  <p class="lead mb-0">
    Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
    worden meerdere onderdelen vervangen.
  </p>
  <h1 class="text-warning">Huawei</h1>



  <article class="mb-2 p-2 art" v-if="global">
    <details v-for="h in huwai" :key="h.naam">
      <summary>
        {{ h.naam }}
      </summary>
      <div class="box">
        <h4>
          LCD: <span>{{ h.LCD }}</span>
        </h4>
        <h4 v-if="h.Accu">
          Accu: <span>{{ h.Accu }}</span>
        </h4>
        <h4 v-if="h.Oplaadconnector">
          Oplaad Connector: <span>{{ h.Oplaadconnector }}</span>
        </h4>
      </div>
    </details>

    <br />

    <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
      >Alle merken</router-link
    >
  </article>

  <article v-if='!global' class="my-2 px-2">
    <table>
      <tr>
        <th>Merk</th>
        <th>LCD</th>
        <th>Accu</th>
        <th>Oplaad Connector</th>
      </tr>
      <tr v-for="hu in huwai" :key="hu.naam">
        <td class="py-1">{{ hu.naam }}</td>
        <td>{{ hu.LCD }}</td>
        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>
      </tr>
    </table>
      <br />

    <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
      >Alle merken</router-link
    >
  </article>


  
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.art {
  @include md {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @include lg {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}



</style>
