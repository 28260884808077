<template>
  <p class="lead">
    Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
    worden meerdere onderdelen vervangen.
  </p>
  <h1 class="text-warning">Nokia</h1>
  <article class="mb-1 p-2 art" v-if="global">
    <details v-for="no in nokia" :key="no.naam">
      <summary>
        {{ no.naam }}
      </summary>
      <div class="box">
        <h4 v-if="no.Glas">
          Glas: <span>{{ no.Glas }}</span>
        </h4>
        <h4 v-if="no.LCD">
          LCD: <span>{{ no.LCD }}</span>
        </h4>
        <h4 v-if="no.Accu">
          Accu: <span>{{ no.Accu }}</span>
        </h4>
        <h4 v-if="no.Oplaadconnector">
          Oplaadconnector: <span>{{ no.Oplaadconnector }}</span>
        </h4>
        <h4 v-if="no.Speaker">
          Speaker: <span>{{ no.Speaker }}</span>
        </h4>
        <h4 v-if="no.Luidspreker">
          Luidspreker: <span>{{ no.Luidspreker }}</span>
        </h4>
        <h4 v-if="no.Backcover">
          Backcover: <span>{{ no.Backcover }}</span>
        </h4>
      </div>
    </details>
  </article>

  <article v-if="!global" class="my-2 px-2">
    <table>
      <tr>
        <th>Merk</th>
        <th>LCD</th>
      </tr>
      <tr v-for="hu in nokia" :key="hu.naam">
        <td class="py-1" v-if="hu.naam">{{ hu.naam }}</td>
        <td v-if="!hu.naam">n.v.t</td>
        <td v-if="hu.LCD">{{ hu.LCD }}</td>
        <td v-if="!hu.LCD">n.v.t</td>
      </tr>
    </table>
  </article>

  <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
    >Alle merken</router-link
  >
  <br />
  <br />
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.art {
  @include md {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @include lg {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
</style>
