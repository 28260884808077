const diverse = [
  {
    id: 1,
    title: 'Xiaomi',
    url: 'xiaomi.png',
  },
  {
    id: 2,
    title: 'Acer',
    url: 'acer.png',
  },
  {
    id: 3,
    title: 'OnePlus',
    url: 'OnePlus_Logo.png',
  },
  {
    id: 4,
    title: 'Alcatel',
    url: 'alcatel-logo.png',
  },
  {
    id: 5,
    title: 'Asus',
    url: 'asus_logo.png',
  },
  {
    id: 6,
    title: 'Blackberry',
    url: 'blackberry-logo.png',
  },
  {
    id: 7,
    title: 'Meizu',
    url: 'meizu-logo.png',
  },
  {
    id: 8,
    title: 'Energizer',
    url: 'energizer-logo-500x127.png',
  },
  {
    id: 9,
    title: 'Micromax',
    url: 'micromax-mobile-logo.png',
  },
  {
    id: 10,
    title: 'Microsoft',
    url: 'microsoft-logo-500x107.png',
  },
  {
    id: 11,
    title: 'Motorola',
    url: 'motorola-logo.png',
  },
  {
    id: 12,
    title: 'Oppo',
    url: 'oppo-logo.png',
  },
  {
    id: 13,
    title: 'Realme',
    url: 'realme-logo.png',
  },
  {
    id: 14,
    title: 'Tecno',
    url: 'tecno_mobile_logo_freelogovectors.net_-400x150.png',
  },
  {
    id: 15,
    title: 'Vivo',
    url: 'vivo-logo.png',
  },
  {
    id: 16,
    title: 'Zte',
    url: 'zte-logo-500x242.png',
  },
]

export default diverse
