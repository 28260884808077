<template>
	<div
		class="alert bg-primary text-warning"
		v-if="aded"
	>
		<h2>Bedankt</h2>
		<p>Uw bericht is verzonden en wij nemen snel contact met u op.</p>
	</div>
	<article class="p-3">
		<section class="sec1">
			<p class="p-1 text-primary">
				<i class="fa fa-envelope"></i> Email <br />
				<a
					href="mailto:info@q-mobile.eu"
					class="text-dark"
				>
					info@q-mobile.eu</a
				>
			</p>
			<p class="p-1 text-primary">
				<i class="fa fa-phone"></i> Mobile <br />
				<a
					href="tel:0031683313343"
					class="text-dark"
				>
					0683313343</a
				>
			</p>
			<address class="p-1 text-primary">
				<i class="fa fa-map mr-1"></i> <br />
				<a
					href="https://maps.app.goo.gl/dF8PnU95rufRKWD4A"
					target="_blank"
					class="text-dark"
					>Langestraat 58 <br>
            8281 AM Genemuiden</a
				>
			</address>

			<div class="socials">
				<h2 class="text-primary">Volg ons op</h2>
				<div class="soc">
					<a
						href="tel:0031683313343"
						target="_blank"
						><i class="fa fa-whatsapp fa-3x mx-1"></i
					></a>
					<a
						href=""
						target="_blank"
						><i class="fab fa-facebook fa-3x mx-1 fb"></i
					></a>
					<a
						href="https://www.instagram.com/q_mobile369/"
						target="_blank"
						><i class="fa fa-instagram fa-3x mx-1"></i
					></a>
				</div>
			</div>
		</section>

		<section class="sec2">
			<p class="lead mb-1">U kunt altijd een bericht sturen</p>
			<h6 class="mb-1">Velden met &ast; zijn verplicht</h6>
			<form @submit.prevent="verzend">
				<label for="naam">Naam&ast;</label>
				<input
					type="text"
					v-model="naam"
					required
					id="naam"
					name="naam"
				/>

				<br />
				<label for="email">Email&ast;</label>
				<input
					type="email"
					id="email"
					name="email"
					v-model="email"
					required
				/>

				<br />
				<label for="tel">Telefoon</label>
				<input
					type="tel"
					name="tel"
					id="tel"
					v-model="tel"
				/>
				<br />
				<label for="textarea">Bericht</label>
				<textarea
					name="textarea"
					id="area"
					cols="60"
					rows="10"
					v-model="text"
				>
				</textarea>

				<button
					type="submit"
					class="btn btn-primary"
				>
					Verzenden
				</button>
			</form>
		</section>
	</article>
</template>

<script>
	import service from "@/services/QMobileService"
	export default {
		name: "GegeVens",

		data() {
			return {
				naam: "",
				email: "",
				tel: null,
				text: "",
				aded: false,
				createdAt: new Date(),
			}
		},

		methods: {
			verzend() {
				const newKarbar = {
					naam: this.naam,
					email: this.email,
					telefoon: this.tel,
					text: this.text,
					createdAt: this.createdAt,
				}

				// Bericht toevoegen
				service.addMSG(newKarbar)

				// Verzend Bericht tonen
				this.aded = true

				// Een vertraging maken
				setTimeout(() => {
					this.$router.push("/")
				}, 4000)
			},
		},
		// },
	}
</script>

<style
	scoped
	lang="scss"
>
	article {
		max-width: 1000px;
		margin: 0 auto;
		display: grid;

		@include md {
			grid-template-columns: 1fr 1fr;
		}
	}

	input {
		margin-bottom: 10px;
		border-radius: 0.5rem;
		padding: 0.3rem;
	}
	.lead {
		width: 100%;
		padding: 0;
		font-size: 25px;
		text-align: center;
		margin-bottom: 20px;
	}
	.sec1 {
		width: 100%;
		margin: -2rem 0 20px 0;

		@include md {
			margin: 0;
		}
	}

	.sec2 {
		width: 100%;
		margin-top: 5rem;

		@include md {
			margin-top: 0;
		}
	}
	.text-primary {
		font-size: 1.8rem;
	}

	.btn {
		width: auto;
		display: flex;
		margin: 0 auto;
		justify-content: center;
		margin-top: 10px;
	}
	.btn:hover {
		transition: all 1.2s ease;
	}

	textarea {
		width: 100%;
		margin-top: 10px;
		border-radius: 0.5rem;
	}

	.alert {
		width: auto;
		top: 73px;
		margin-left: 200px;
		position: fixed;
		height: 90px;
		padding: 10px 15px;
		border-radius: 7px;
		text-align: center;
		animation: fromTop 1s ease;
	}
	.alert p {
		line-height: 1.8;
		padding: 5px;
	}

	.socials {
		margin-left: 22px;
		margin-top: 50px;

		h2 {
			margin: 10px auto;
		}
	}

	@keyframes fromTop {
		0% {
			top: 0;
		}
		100% {
			top: 80px;
		}
	}
</style>
