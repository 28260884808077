<template>
  <p class="lead">
    Bij reparaties die een <span class="text-warning">&ast;</span> bevatten
    worden meerdere onderdelen vervangen.
  </p>
  <h1 class="text-warning">Samsung</h1>
  <article class="art mb-1 px-2" v-if="global">
    <h3 class='mt-1'>Galaxies</h3>
    <details v-for="s in samsung" :key="s.naam">
      <summary>{{ s.naam }}</summary>
      <div class="box">
        <h4 v-if="s.Scherm">
          Scherm: <span>{{ s.Scherm }}</span>
        </h4>
        <h4 v-if="s.LCD">
          Scherm: <span>{{ s.LCD }}</span>
        </h4>
        <h4 v-if="s.BackcoverOrigineel">
          Backcover Origineel: <span>{{ s.BackcoverOrigineel }}</span>
        </h4>
        <h4 v-if="s.BackcoverAAA">
          Backcover AAA+: <span>{{ s.BackcoverAAA }}</span>
        </h4>
        <h4 v-if="s.Accu">
          Accu: <span>{{ s.Accu }}</span>
        </h4>
        <h4 v-if="s.Oplaadconnector">
          Oplaad connector: <span>{{ s.Oplaadconnector }}</span>
        </h4>
        <h4 v-if="s.SoftwareAccount">
          Software Account: <span>{{ s.SoftwareAccount }}</span>
        </h4>
        <h4 v-if="s.Luidspreker">
          Luidspreker: <span>{{ s.Luidspreker }}</span>
        </h4>
        <h4 v-if="s.Camera">
          Camera: <span>{{ s.Camera }}</span>
        </h4>
        <h4 v-if="s.CameraLens">
          Camera Lens: <span>{{ s.CameraLens }}</span>
        </h4>
      </div>
    </details>

    <hr />
    <h3 class='mt-1'>Notes</h3>
    <details v-for="n in notes" :key="n.naam">
      <summary>{{ n.naam }}</summary>
      <div class="box">
        <h4 v-if="n.Glas">
          Glas: <span>{{ n.Glas }}</span>
        </h4>
        <h4 v-if="n.LCD">
          LCD: <span>{{ n.LCD }}</span>
        </h4>
        <h4 v-if="n.Oplaadconnector">
          Oplaad connector: <span>{{ n.Oplaadconnector }}</span>
        </h4>
        <h4 v-if="n.Speaker">
          Speaker: <span>{{ n.Speaker }}</span>
        </h4>
      </div>
    </details>
    <hr />
    <h3 class='mt-1'>Tabs</h3>
    <details v-for="t in tab" :key="t.naam">
      <summary>{{ t.naam }}</summary>
      <div class="box">
        <h4 v-if="t.Glas">
          Glas: <span>{{ t.Glas }}</span>
        </h4>
        <h4 v-if="t.LCD">
          LCD: <span>{{ t.LCD }}</span>
        </h4>
        <h4 v-if="t.Accu">
          Accu: <span>{{ t.Accu }}</span>
        </h4>
        <h4 v-if="t.Oplaadconnector">
          Oplaad connector: <span>{{ t.Oplaadconnector }}</span>
        </h4>
        <h4 v-if="t.Speaker">
          Speaker: <span>{{ t.Speaker }}</span>
        </h4>
        <h4 v-if="t.Luidspreker">
          Luidspreker: <span>{{ t.Luidspreker }}</span>
        </h4>
      </div>
    </details>
  </article>
  <article v-if="!global" class="my-2 px-2">
    <table>
      <tr>
        <th>Galaxies</th>
        <th>Scherm</th>
        <th>Backcover origineel</th>
        <th>Backcover AAA</th>
        <th>Accu</th>
        <th>Oplaad Connector</th>
        <th>SoftwareAccount</th>
        <th>Camera</th>
        <th>CameraLens</th>
      </tr>

      <tr v-for="hu in samsung" :key="hu.naam">
        <td class="py-1" v-if="hu.naam">{{ hu.naam }}</td>
        <td v-if="!hu.naam">n.v.t</td>
        <td v-if="hu.Scherm">{{ hu.Scherm }}</td>
        <td v-if="!hu.Scherm">n.v.t</td>
        <td v-if="hu.BackcoverOrigineel">{{ hu.BackcoverOrigineel }}</td>
        <td v-if="!hu.BackcoverOrigineel">n.v.t</td>
        <td v-if="hu.BackcoverAAA">{{ hu.BackcoverAAA }}</td>
        <td v-if="!hu.BackcoverAAA">n.v.t</td>
        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>
        <td v-if="hu.SoftwareAccount">{{ hu.SoftwareAccount }}</td>
        <td v-if="!hu.SoftwareAccount">n.v.t</td>
        <td v-if="hu.Camera">{{ hu.Camera }}</td>
        <td v-if="!hu.Camera">n.v.t</td>
        <td v-if="hu.CameraLens">{{ hu.CameraLens }}</td>
        <td v-if="!hu.CameraLens">n.v.t</td>
      </tr>
    </table>

    <table>
      <tr>
        <th>Notes</th>
        <th>Glas</th>
        <th>LCD</th>
        <th>Oplaadconnector</th>
      </tr>

      <tr v-for="no in notes" :key="no.naam">
        <td class="py-1" v-if="no.naam">{{ no.naam }}</td>
        <td v-if="!no.naam">n.v.t</td>
        <td v-if="no.Glas">{{ no.Glas }}</td>
        <td v-if="!no.Glas">n.v.t</td>
        <td v-if="no.LCD">{{ no.LCD }}</td>
        <td v-if="!no.LCD">n.v.t</td>
        <td v-if="no.Oplaadconnector">{{ no.Oplaadconnector }}</td>
        <td v-if="!no.Oplaadconnector">n.v.t</td>
      </tr>
    </table>

    <table>
      <tr>
        <th>Tabs</th>
        <th>Glas</th>
        <th>LCD</th>
        <th>Accu</th>
        <th>Oplaad connector</th>
        <th>Speaker</th>
        <th>Luidspreker</th>
      </tr>

      <tr v-for="ta in tab" :key="ta.naam">
        <td class="py-1" v-if="ta.naam">{{ ta.naam }}</td>
        <td v-if="!ta.naam">n.v.t</td>
        <td v-if="ta.Glas">{{ ta.Glas }}</td>
        <td v-if="!ta.Glas">n.v.t</td>
        <td v-if="ta.LCD">{{ ta.LCD }}</td>
        <td v-if="!ta.LCD">n.v.t</td>
        <td v-if="ta.Accu">{{ ta.Accu }}</td>
        <td v-if="!ta.Accu">n.v.t</td>
        <td v-if="ta.Oplaadconnector">{{ ta.Oplaadconnector }}</td>
        <td v-if="!ta.Oplaadconnector">n.v.t</td>
        <td v-if="ta.Speaker">{{ ta.Speaker }}</td>
        <td v-if="!ta.Speaker">n.v.t</td>
        <td v-if="ta.Luidspreker">{{ ta.Luidspreker }}</td>
        <td v-if="!ta.Luidspreker">n.v.t</td>
      </tr>
    </table>
  </article>
  <router-link class="btn btn-success" :to="{ name: 'Tarieven' }"
    >Alle merken</router-link
  >
  <br />
  <br />
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.art {
  @include md {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @include lg {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
</style>
