<template>
  <div class="p-2">
    <Accessoires />
  </div>
</template>

<script>
import Accessoires from '@/components/Accessoires.vue'
export default {
  name: 'AccesView',
  components: { Accessoires },
}
</script>
