<template>
	<hr />
	<article class="grid">
		<section class="secInfo foto">
			<h1 class="">Q-Mobile</h1>
			<router-link to="/"
				><img
					src="@/assets/logo.png"
					alt="LogoBottom"
					class="footImg"
			/></router-link>
			<div class="soc">
				<a
					href="tel:0031683313343"
					target="_blank"
					><i class="fa fa-whatsapp fa-3x mx-1"></i
				></a>
				<a
					href=""
					target="_blank"
					><i class="fab fa-facebook fa-3x mx-1 fb"></i
				></a>
				<a
					href="https://www.instagram.com/q_mobile369/"
					target="_blank"
					><i class="fa fa-instagram fa-3x mx-1"></i
				></a>
			</div>
		</section>
		<section class="secInfo opening">
			<h1 class="">Openingstijden</h1>
			<p>
				<span>Maandag: </span><strong>13:00-18:00</strong><br />
				<span>Dinsdag t/m Vrijdag: </span><strong>10:00-18:00</strong><br />
				<span>Zaterdag: </span><strong>10:00-16:00</strong><br />
				<span>Zondag: </span><strong>Gesloten</strong><br />

				<span>Wij zijn met de kerstdagen gewoon open! </span>
			</p>
		</section>
		<section class="secInfo contact">
			<h1 class="h">Contact</h1>
			<p>
				<i class="fa fa-envelope text-primary mr-1"></i>
				<a
					href="mailto:info@q-mobile.eu"
					target="_blank"
					class="text-dark"
				>
					info@q-mobile.eu</a
				>
			</p>

			<p>
				<i class="fa fa-phone text-primary mr-1"></i>
				<a
					href="tel:0031683313343"
					class="text-dark"
					target="_blank"
				>
					0683313343</a
				>
			</p>

			<address>
				<i class="fa fa-store text-primary mr-1"></i>
				<a
					href="https://maps.app.goo.gl/dF8PnU95rufRKWD4A"
					target="_blank"
					class="text-dark"
					>Langestraat 58, 8281 AM Genemuiden</a
				>
			</address>
		</section>
	</article>
</template>

<style
	scoped
	lang="scss"
>
	.grid {
		display: grid;
		width: 100%;
		gap: 0 5px;
		padding: 5px;
		// width: min(100% - 3rem, 1400px);
		margin-inline: auto;

		@include md {
			grid-template-columns: repeat(2, 1fr);
			padding: 1rem;
		}
		@include lg {
			grid-template-columns: repeat(3, 1fr);
			padding: 1rem 0;
		}
	}

	.h {
		margin-bottom: 20px;

		@include lg {
			margin-bottom: 3rem;
		}
	}

	.secInfo {
		width: 100%;
		text-align: center;
		// border: 1px solid blue;
	}

	.footImg {
		width: 200px;
		height: 50px;
		padding: 4px 0;
		margin-top: 30px;
		border-radius: 0.5rem;
	}

	.opening p {
		justify-items: center;
		justify-content: center;
		width: min(100% - 4rem);
		margin-inline: auto;
		/* border:1px solid green; */
		padding: 20px;
		text-align: left;

		strong {
			float: right;
		}

		span {
			line-height: 1.6;
		}
	}

	.contact p {
		padding: 7px 0;
		display: block;
		justify-content: center;

		@include md {
			padding-left: 0;
		}
	}
	.contact a {
		display: inline;
	}
	.contact i {
		display: inline;
	}
	.contact {
		text-align: center;
		width: 100%;
		margin: 0 auto;
		justify-content: left;
	}
	.contact address {
		display: block;
		width: auto;
		/* border: 1px solid blue; */
		justify-content: space-between;
		padding: 7px 0;
	}

	.contact address i {
		margin-right: 10px !important;
	}

	.text-dark {
		display: block;
		text-align: center;
	}
</style>
