<template>
  <h1>Accessoires</h1>
  <p class="font-xl my-3">
    Kom naar onze winkel voor accessoires voor jou mobiele telefoon en tablets:
  </p>
  <article class=''>
    <p v-for="item in access" :key="item" class="p-1">
      {{ item }}
    </p>
  </article>
</template>

<script>
export default {
  name: 'AcceSsoires',
  data() {
    return {
      access: [
        'Hoesjes',
        'Book Case hoesjes',
        'Telefoontasje',
        'Flip Cover',
        'Schermprotectors',
        'Powerbanks',
        'Draadloze opladers',
        'Oplaadkabels',
        'Micro-USB',
        'Geheugenkaarten',
        'Telefoonhouders',
        'Oordopjes',
        'Speakers',
        'Koptelefoons',
      ],
    }
  },
}
</script>

<style lang="scss" scoped> 
article {
  p {
    // background-color: darken($light, 20%);
    color: $dark;
    font-weight: 500;
    font-size: $l-font;

    @include md {
      padding: 1rem;
    }
    @include lg {
      padding: 2rem;
    }
  }

  @include md {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 0.2rem;
  }
  @include lg {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 0.2rem;
  }
}
</style>
