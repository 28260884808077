// import data from '../products/index'
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import FootSection from '@/components/FootSection.vue'
import Icons from '@/components/Icons.vue'
import producten from '@/products/productsData'
export default {
  data() {
    return {
      welkom: 'Welkom bij Q-Mobile',
      all: producten.productsData,
      iphones: producten.productsData.iphones,
      ipads: producten.productsData.ipads,
      huwai: producten.productsData.huwai,
      samsung: producten.productsData.samsungs,
      notes: producten.productsData.notes,
      tab: producten.productsData.tab,
      lg: producten.productsData.lg,
      htc: producten.productsData.htc,
      nokia: producten.productsData.nokia,
      sony: producten.productsData.sony,
      global: null,
      windowWidth: null,
      zoek: '',
    }
  },

  created() {
    window.addEventListener('resize', this.checkScreenTw)
    this.checkScreenTw()
  },

  methods: {
    checkScreenTw() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 720) {
        this.global = true
        return
      }
      this.global = false
    },
  },

  components: { Icons, NavBar, Footer, FootSection },
}
