import { db } from '@/data/db'
import { collection, addDoc } from 'firebase/firestore'

export default new (class QMobileService {
  addMSG(obj) {
    console.log('OK', obj.id)
    return addDoc(collection(db, 'karbaran'), obj)
  }
})()

/*   addMSG(obj) {
    try {
      const docRef = addDoc(collection(db, 'karbaran'), obj)
      console.log('OK', docRef.id)
    } catch (error) {
      console.error('Error is gevonden tijdens toevoegen: ', error)
    }
  } */
