<template>
  <h1 class="mt-1">Onze Tarieven</h1>
  <h3>Reparatiekosten per merk</h3>

  <!-- <Zoeken /> -->

  <article class="">
    <section>
      <div class="flow">
        <router-link to="/tarieven/apple">
          <img
            src="@/assets/images/brands/apple-black-logo.png"
            alt="Apple Logo"
            class="aks"
        /></router-link>
        <router-link to="/tarieven/samsung">
          <img
            src="@/assets/images/brands/samsung.png"
            alt="Samsung Logo"
            class="aks"
        /></router-link>
        <router-link to="/tarieven/huawei">
          <img
            src="@/assets/images/brands/huawei.png"
            alt="Huawei Logo"
            class="aks"
        /></router-link>
        <router-link to="/tarieven/lg">
          <img
            src="@/assets/images/brands/lg.png"
            alt="LG Logo"
            class="aks drie"
        /></router-link>
        <router-link to="/tarieven/htc">
          <img src="@/assets/images/brands/htc.png" alt="HTC Logo" class="aks"
        /></router-link>
        <router-link to="/tarieven/sony">
          <img
            src="@/assets/images/brands/sony.png"
            alt="Sony Logo"
            class="aks"
        /></router-link>
        <router-link to="/tarieven/nokia">
          <img
            src="@/assets/images/brands/nokia.png"
            alt="Nokia Logo"
            class="aks"
        /></router-link>
      </div>
      <br />
      <p class="lead">Daarnaast doen wij ook reparaties voor diverse merken</p>
      <div class="diverse">
        <img
          v-for="img in diverse"
          :key="img.id"
          :src="imgFinder(img.url)"
          :alt="`Foto van ${img.tilte} is niet gevonden.`"
          :title="img.title"
          class="aks mx-1"
        />
      </div>
    </section>

    <aside>
      <Links />
    </aside>
  </article>
</template>

<script>
import Links from '@/components/Links.vue'
import diverse from '@/products/diverse'
export default {
  name: 'TarIeven',
  components: { Links },

  data() {
    return {
      diverse: diverse,
    }
  },

  methods: {
    imgFinder(img) {
      return require('@/assets/images/brands/diverse/' + img)
    },
  },
}
</script>

<style scoped lang="scss">
article {
  width: 100%;
  height: auto;
  display: block;

  @include lg {
    display: grid;
    justify-content: center;
    grid-template-columns: 3fr 1fr;
    margin-top: 3rem;
  }

  .flow {
    padding: 80px 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: -4rem;

    .aks {
      width: 100px;
      height: auto;
    }

    .drie {
      margin-top: 50px;
    }

    @include md {
      grid-template-columns: repeat(2, 1fr);
      .drie {
        margin-top: 0;
      }
    }
    @include lg {
      grid-template-columns: repeat(4, 1fr);
      padding: 1rem;
      margin-bottom: 2rem;
    }
  }

  .diverse {
    padding: 1rem;
    margin-bottom: 2rem;
    // background-color: lighten($dark, 20%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 1rem;

    @include md {
      grid-template-columns: repeat(3, 1fr);
      padding-left: 6rem;
    }
    @include lg {
      grid-template-columns: repeat(4, 1fr);
      padding-left: 4rem;
      justify-items: center;
    }

    .aks {
      width: 100px;
      height: auto;
      margin: 0.5rem 0;
    }
  }
}

section {
  width: 100%;
  display: block;
  @include lg {
    width: 100%;
    padding: 1rem;
  }
}

aside {
  display: block;
  width: 100%;

  @include lg {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
  }
}
</style>
