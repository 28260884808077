<template>
  <article v-if="global">
    <details v-for="ipad in ipads" :key="ipad.naam">
      <summary class="summ">
        {{ ipad.naam }}
      </summary>
      <div class="box">
        <h4>
          Glas: <span>{{ ipad.Glas }}</span>
        </h4>
        <h4>
          Oplaad connector: <span>{{ ipad.Oplaadconnector }}</span>
        </h4>
        <h4>
          Accu: <span>{{ ipad.Accu }}</span>
        </h4>
        <h4>
          Glas Protector: <span>{{ ipad.GlasProtector }}</span>
        </h4>
      </div>
    </details>
  </article>
  <article v-if="!global" class="my-3">
    <table>
      <tr>
        <th>Merk</th>
        <th>Glas</th>
        <th>Oplaad Connector</th>
        <th>Accu</th>
        <th>Glas Protector</th>
      </tr>
      <tr v-for="hu in ipads" :key="hu.naam">
        <td class="py-1" v-if="hu.naam">{{ hu.naam }}</td>
        <td v-if="!hu.naam">n.v.t</td>
        <td v-if="hu.Glas">{{ hu.Glas }}</td>
        <td v-if="!hu.Glas">n.v.t</td>
        <td v-if="hu.Oplaadconnector">{{ hu.Oplaadconnector }}</td>
        <td v-if="!hu.Oplaadconnector">n.v.t</td>
        <td v-if="hu.Accu">{{ hu.Accu }}</td>
        <td v-if="!hu.Accu">n.v.t</td>
        <td v-if="hu.GlasProtector">{{ hu.GlasProtector }}</td>
        <td v-if="!hu.GlasProtector">n.v.t</td>
      </tr>
    </table>
  </article>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
